import { LogoutIcon } from "@heroicons/react/outline";
import { Button } from "./Button";
import { Container } from "./Container";

export const Header = () => {
  return (
    <>
      <div className="py-4">
        <Container>
          <div className="flex items-center justify-between font-medium">
            <div className="flex items-center gap-x-3">
              <div className="inline-flex p-[3px] rounded-full bg-gradient-to-b from-[#9053FF] to-[#fff]">
                <img
                  className="h-9 w-9 rounded-full object-cover"
                  src="https://static-cdn.jtvnw.net/jtv_user_pictures/f3591dbe4ee3d94b-profile_image-70x70.png"
                />
              </div>
              <span className="text-[15px]">تایلر وان</span>
            </div>
            <div className="flex gap-x-5">
              <Button type="secondary">صفحه حمایت من</Button>
              <Button type="secondary">
                <LogoutIcon className="w-4 h-4 text-[#6C727F] scale-x-[-1] -mx-1.5" />
              </Button>
            </div>
          </div>
        </Container>
      </div>
      <hr className="border-[#e9e9e9] mb-10" />
    </>
  );
};
