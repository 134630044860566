import { Container } from "../../components/Container";
import { Layout } from "../../components/Layout";
import { Accounts } from "./components/Accounts";
import { Activities } from "./components/Activities";
import { DiscordCard } from "./components/DiscordCard";
import { StatsChart } from "./components/StatsChart";
import { TutorialCard } from "./components/TutorialCard";

export const Dashboard = () => {
  return (
    <Layout>
      <Container>
        <div className="flex gap-x-12 2xl:gap-x-14">
          <div className="w-8/12 gap-y-8 flex flex-col">
            <TutorialCard />
            <StatsChart />
            <Activities />
          </div>
          <div className="w-4/12 gap-y-8 flex flex-col">
            <Accounts />
            {/* <DiscordCard /> */}
          </div>
        </div>
      </Container>
    </Layout>
  );
};
