import axios from "axios";
import Cookies from "universal-cookie";
import { errorHandler } from "./errorHandler";

const cookies = new Cookies();
const userToken = () => "Bearer " + cookies?.get("access_token");

const instance = axios.create({
  baseURL: `https://user-api.dono.gg/v1`,
  timeout: 60000,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => errorHandler(error)
);

instance.interceptors.request.use(
  function (config) {
    if (cookies?.get("access_token")) {
      if (typeof config?.headers !== "undefined") {
        config.headers["Authorization"] = userToken();
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const { get, post, put, delete: remove, patch } = instance;

export { get, post, put, remove, patch };
