import { useFormContext } from "react-hook-form";
import { classNames } from "../utils/classNames";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

export default function Input({
  label,
  placeholder = "",
  helperText = "",
  id,
  type = "text",
  readOnly = false,
  validation,
  manual,
  ...rest
}: {
  label?: string;
  placeholder?: string;
  helperText?: string;
  id: string;
  type?: "text" | "number" | "password";
  readOnly?: boolean;
  validation?: { required: string };
  manual?: boolean;
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext() || { formState: { errors: {} } };

  return (
    <div className="w-full">
      <label htmlFor={id} className="block text-sm font-normal text-gray-700">
        {label}
      </label>
      <div className="relative mt-1">
        <input
          {...(!manual &&
            register(id, { valueAsNumber: type === "number", ...validation }))}
          {...rest}
          type={type}
          name={id}
          id={id}
          readOnly={readOnly}
          className={classNames(
            "py-3 px-3 border placeholder-[#6D7588]",
            readOnly
              ? "bg-gray-100 focus:ring-0 cursor-not-allowed border-gray-300 focus:border-gray-300"
              : !manual && errors[id]
              ? "focus:ring-red-500 border-red-300 border-2 focus:border-red-500"
              : "focus:ring-indigo-500 border-gray-200 focus:border-indigo-500",
            "block w-full rounded-[10px] text-[15px]"
          )}
          style={{ boxShadow: "0px 4px 2px rgba(113, 114, 125, 0.03)" }}
          placeholder={placeholder}
          aria-describedby={id}
        />

        {!manual && errors[id] && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <ExclamationCircleIcon className="text-xl text-red-500" />
          </div>
        )}
      </div>
      <div className="mt-1">
        {helperText !== "" && (
          <p className="text-xs text-gray-500">{helperText}</p>
        )}
        {!manual && errors[id] && (
          <span className="text-sm text-red-500">{errors[id].message}</span>
        )}
      </div>
    </div>
  );
}
