import { Container } from "../../components/Container";
import { Layout } from "../../components/Layout";
import { TutorialCard } from "./components/TutorialCard";
import { Card as OverlayCard } from "./components/Card";
import overlayDefault from "../../images/overlays/1.png";
import { useFetch } from "../../hooks/useFetch";
import { Overlay } from "../../api/overlay";
import { NewOverlayCard } from "./components/NewOverlayCard";

type IOverlay = {
  id: string;
  userId: string;
  key: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
};

export const Overlays = () => {
  const {
    data: overlays,
    loading,
    fetchData,
  } = useFetch((p) => Overlay.getAll({ ...p }));

  return (
    <Layout>
      <Container>
        <TutorialCard />
        <h2 className="font-semibold mb-6">نمایه ها</h2>
        <div className="grid grid-cols-4 gap-x-12">
          {overlays?.data?.map((overlay: IOverlay) => (
            <OverlayCard
              key={overlay?.id}
              title={overlay?.name}
              image={overlayDefault}
              date={overlay?.updatedAt}
            />
          ))}
          <NewOverlayCard />
        </div>
      </Container>
    </Layout>
  );
};
