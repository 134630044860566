import { Container } from "../../components/Container";
import { Layout } from "../../components/Layout";
import { Payments } from "./components/Payments";
import { TutorialCard } from "./components/TutorialCard";

export const Donations = () => {
  return (
    <Layout>
      <Container>
        <TutorialCard />
        <Payments />
      </Container>
    </Layout>
  );
};
