import { toast as _toast } from "react-hot-toast";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { ReactNode } from "react";

class Toast {
  private template(title: ReactNode) {
    return _toast.custom((t) => {
      return (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0 pt-0.5"></div>
              <div className="flex-1">
                <p className="text-sm font-medium text-gray-900">{title}</p>
              </div>
            </div>
          </div>
          <div className="flex">
            <button
              onClick={() => _toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-l-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              بستن
            </button>
          </div>
        </div>
      );
    });
  }

  success(message: string) {
    return this.template(
      <div className="flex items-center">
        <div className="ml-2">
          <CheckCircleIcon className="w-4 h-4 ml-1 text-green-400" />
        </div>{" "}
        {message || "با موفقیت انجام شد."}
      </div>
    );
  }

  error(message: string) {
    return this.template(
      <div className="flex items-center">
        <div className="ml-2">
          <XCircleIcon className="w-4 h-4 text-red-400" />
        </div>
        {message || "عملیات با خطا مواجه شد."}
      </div>
    );
  }

  info(message: string) {
    return this.template(
      <div className="flex items-center">
        <div className="ml-2">
          <InformationCircleIcon className="w-4 h-4 text-violet-400" />
        </div>
        {message}
      </div>
    );
  }
}

export default new Toast();
