import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import fa from "timeago.js/lib/lang/fa";
import { ZarinpalIcon } from "../../../images/ZarinpalIcon";
timeago.register("fa", fa);

const renderGateway = (gateway: string) => {
  switch (gateway) {
    case "ZARINPAL":
      return (
        <div className="flex gap-x-2.5 items-center">
          <ZarinpalIcon />
          <span>زرین‌پال</span>
        </div>
      );
  }
};

const renderCurrency = (currency: string) => {
  switch (currency) {
    case "IRR":
      return <span>ریال</span>;
    case "IRT":
      return <span>تومان</span>;
    case "USD":
      return <span>دلار</span>;
    case "EUR":
      return <span>یورو</span>;
    case "GBP":
      return <span>پوند</span>;
    case "RUB":
      return <span>روبل</span>;
    case "TRY":
      return <span>لیر</span>;
  }
};

export const model = [
  {
    Header: "نام",
    accessor: "metadata",
    Cell: ({ value, row }: any) =>
      value && (
        <div className="flex flex-col gap-y-1">
          <span className="text-black font-semibold text-[15px]">
            {value?.donator}
          </span>
          <span className="text-xs opacity-60 font-medium">
            <TimeAgo
              datetime={value?.createdAt}
              locale="fa"
              className="dono-dock-time mb-0"
            />
          </span>
        </div>
      ),
  },
  {
    Header: "مبلغ",
    Cell: ({ row }: any) =>
      row && (
        <div className="flex gap-x-1 price text-[15px]">
          {row?.original?.metadata?.amount?.toLocaleString("fa-IR")}{" "}
          {renderCurrency(row?.original?.metadata?.currency)}
        </div>
      ),
  },
  {
    Header: "درگاه",
    Cell: ({ row }: any) =>
      row && (
        <span className=" text-[15px]">
          {renderGateway(row?.original?.metadata?.paymentMethodType)}
        </span>
      ),
  },
  {
    Header: "توضیحات",
    Cell: ({ row }: any) => (
      <div className="flex gap-x-4 text-[15px]">
        {row?.original?.metadata?.message}
      </div>
    ),
  },
];
