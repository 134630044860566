import { classNames } from "../../../utils/classNames";
import coyote from "../../../images/coyote.png";

export const TutorialCard = () => {
  return (
    <div
      className={classNames(
        "bg-primary text-white py-6 px-8 rounded-[20px] relative pl-[185px]"
      )}
    >
      <div className="flex items-center">
        <div>
          <h2 className="text-base font-medium mb-2">
            ثبت نام شما در دونو تکمیل شد
          </h2>
          <p className="text-sm opacity-90 leading-6">
            ممنون که دونو رو انتخاب کردید، برای اولین قدم یک اکانت جدید لینک
            کنید. از طریق لینک کردن زرین پال، توییچ، آپارات، استریم المنتس و
            دیگر سرویس ها را به دونو متصل کنید.
          </p>
        </div>
        <img src={coyote} className="-mt-8 absolute top-0 left-7" />
      </div>
    </div>
  );
};
