import { Dispatch, useState, SetStateAction } from "react";
import OtpInput from "react-otp-input";
import { Button } from "../../../components/Button";

export const VerifyLogin = ({
  setCodeSent,
}: {
  setCodeSent: Dispatch<SetStateAction<string>>;
}) => {
  const [email] = useState(localStorage.getItem("loginServiceId"));
  const [code, setCode] = useState<string>();

  const handleOtp: (number: string) => void = (otp: string) => setCode(otp);

  return (
    <div className="max-w-[380px]">
      <h1 className="text-3xl font-black mb-4">کد ورود ارسال شد</h1>
      <p className="text-black/80 leading-8 mb-8">
        یک کد ورود برای <strong>{email}</strong> ارسال شده است. در صورتی که این
        کد در اینباکس ایمیل شما یافت نشد لطفا پوشه اسپم خودتون رو چک کنید.
      </p>
      <div className="otp-form mb-8">
        <OtpInput
          value={code}
          onChange={handleOtp}
          numInputs={4}
          separator={<div className="px-1" />}
        />
      </div>
      <div className="flex gap-x-4">
        <Button type="primary">ورود به سایت</Button>
        <Button type="secondary" onClick={() => setCodeSent("")}>
          ارسال مجدد
        </Button>
      </div>
    </div>
  );
};
