import { model } from "../model";
import { Alert } from "../../../api/alert";
import { Card } from "../../../components/Card";
import Table from "../../../components/Table";
import { useFetch } from "../../../hooks/useFetch";

export const Payments = () => {
  const {
    data: payments,
    loading,
    fetchData,
  } = useFetch((p) => Alert.getAll({ ...p, type: "DONO_DONATION" }));

  return (
    <Card title="پرداخت ها">
      <Table
        data={payments?.data}
        columns={model}
        loading={loading}
        pageCount={payments?.pagination?.total}
        fetchData={fetchData}
      />
    </Card>
  );
};
