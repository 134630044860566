import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { classNames } from "../utils/classNames";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { FunctionComponent, ReactNode } from "react";

type NavbarTabProps = {
  path: string;
  title: string;
  disabled?: boolean;
  menuType?: "dashboard";
  Icon?: any;
};

export const NavbarTab = ({
  path,
  title,
  disabled,
  menuType,
  Icon,
}: NavbarTabProps) => {
  const resolved = useResolvedPath(path);
  const active = useMatch({ path: resolved?.pathname });

  if (menuType !== "dashboard") {
    return <></>;
  }

  return (
    <li>
      <Link
        className={classNames(
          "cursor-pointer h-12 flex items-center rounded-xl px-3 w-full text-black outline-none",
          active ? "font-bold bg-[#EBEDFA]" : "font-medium",
          !disabled && "hover:text-neutral-900"
        )}
        to={path}
        onClick={(e) => disabled && e.preventDefault()}
      >
        <div className="flex items-center gap-x-2 text-[15px]">
          <div className="icon">
            <Icon
              className={classNames(
                "w-6 h-6",
                active ? "text-[#6F58FF]" : "text-black"
              )}
            />
          </div>
          <span className={classNames(disabled && "opacity-50")}>{title}</span>
        </div>
      </Link>
    </li>
  );
};
