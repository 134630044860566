import { Container } from "../../components/Container";
import { Logo } from "../../components/Logo";
import { MiniFooter } from "../MiniFooter";
import { LoginForm } from "./components/LoginForm";
import { useEffect, useState } from "react";
import { VerifyLogin } from "./components/VerifyLogin";
import { LoginHero } from "./components/LoginHero";
import { useLoginCallback } from "./useLoginCallback";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const [cookies] = useCookies();
  const [codeSent, setCodeSent] = useState("");
  const navigate = useNavigate();
  useLoginCallback();

  useEffect(() => {
    console.log(cookies?.access_token);

    if (!cookies?.access_token) {
      return;
    }

    navigate("/dashboard");
  }, [cookies, navigate]);

  return (
    <div className="flex">
      <Container className="px-[100px] relative">
        <div className="h-screen flex flex-col justify-between py-6 2xl:py-10 px-6 gap-y-14">
          <Logo />
          {codeSent ? (
            <VerifyLogin {...{ setCodeSent }} />
          ) : (
            <LoginForm {...{ setCodeSent }} />
          )}
          <MiniFooter />
        </div>
        <LoginHero />
      </Container>
    </div>
  );
};
