import { classNames } from "../../../utils/classNames";
import coyote from "../../../images/coyote-refresh.png";

export const TutorialCard = () => {
  return (
    <div
      className={classNames(
        "bg-[#F8F9FF] py-6 px-8 rounded-[20px] relative pl-[300px] mb-7"
      )}
    >
      <div className="flex items-center">
        <div>
          <h2 className="text-base font-bold mb-2">ریفرش خودکار</h2>
          <p className="text-sm opacity-80 leading-6">
            تمامی تراکنش های صفحه حمایت شما در این صفحه نمایش داده میشود. این
            صفحه بصورت خودکار بروزرسانی میشود و به محض انجام شدن حمایت جدید،
            مشخصات حمایت در این صفحه اضافه می شود.
          </p>
        </div>
        <img src={coyote} className="-mt-6 absolute top-0 left-7" />
      </div>
    </div>
  );
};
