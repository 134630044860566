import { classNames } from "../../../utils/classNames";
import goalMockup from "../../../images/donation-goal-mockup.png";

export const TutorialCard = () => {
  return (
    <div
      className={classNames(
        "bg-[#F8F9FF] py-6 px-8 rounded-[20px] relative pl-[300px] mb-8"
      )}
    >
      <div className="flex items-center">
        <div>
          <h2 className="text-base font-bold mb-2">نمایه خاص خودت رو بساز</h2>
          <p className="text-sm opacity-80 leading-6">
            از طریق نمایه ها از بین ویجت های مختلف دونو مانند:‌ آخرین حمایت
            کنندگان، هدف حمایت، ویجت ساباتون و غیره می توانید انتخاب کنید و به
            OBS (یا نرم افزار دیگر استریمینگ)‌ خود اضافه کنید.
          </p>
        </div>
        <img src={goalMockup} className="-mt-4 absolute top-0 left-7" />
      </div>
    </div>
  );
};
