import axios from "axios";
import { AxiosError } from "axios";
import { type } from "os";
import toast from "../helpers/toast";

export const errorHandler = (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    if (typeof error?.response?.data !== "undefined") {
      const { data }: any = error?.response;
      if (typeof data === "undefined") {
        return;
      }
      if (typeof data?.message !== "undefined") {
        toast.error(data?.message);
      }
    }
  } else {
    // Just a stock error
  }

  //errors?.forEach((error: string) => toast.error(error));

  return Promise.reject({ ...error });
};
