export const LogoIcon = () => {
  return (
    <svg
      width="27"
      height="35"
      viewBox="0 0 27 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5356 7.63635C11.8557 7.63635 11.2021 7.79546 10.6952 8.08417L1.84074 13.1283C0.791335 13.7262 0 15.0785 0 16.2741V26.3623C0 27.5579 0.791335 28.9102 1.84067 29.508L10.6952 34.5522C11.2021 34.841 11.8557 35 12.5356 35C13.2157 35 13.8694 34.841 14.3764 34.5522C14.3773 34.5516 14.3782 34.5511 14.3792 34.5506L23.2307 29.508C24.2801 28.9103 25.0714 27.5579 25.0714 26.3623V16.2741C25.0714 15.0785 24.2801 13.7262 23.2308 13.1284L14.3764 8.08417C13.8694 7.79546 13.2157 7.63635 12.5356 7.63635ZM8.80202 24.9635C8.63336 24.8658 8.4663 24.7683 8.30128 24.6709C7.90203 24.4122 7.52143 23.7382 7.52143 23.2749V19.9508C7.52143 19.7368 7.52208 19.5248 7.52383 19.3149C7.54824 18.8387 7.93955 18.1722 8.33909 17.9408L11.2307 16.2663C11.4081 16.1636 11.5842 16.0623 11.7592 15.9629C11.9538 15.8636 12.2389 15.8046 12.5356 15.8046C12.8536 15.8046 13.1593 15.8717 13.3533 15.9841L16.2838 17.6811C16.4472 17.7758 16.6095 17.8706 16.7697 17.9651C17.169 18.2236 17.55 18.898 17.55 19.3614V22.7479C17.5498 22.9401 17.5491 23.1309 17.5477 23.3199C17.5241 23.796 17.1324 24.4639 16.7321 24.6956L13.8035 26.3916C13.6391 26.4867 13.4757 26.5806 13.3132 26.6729C13.1185 26.7725 12.8331 26.8317 12.5356 26.8317C12.2177 26.8317 11.912 26.7647 11.7179 26.6523L8.80202 24.9635Z"
        fill="url(#paint0_linear_204_480)"
      />
      <path
        d="M27 0V24.3969C27 26.1568 26.0818 27.7919 24.5717 28.7208L20.5714 31.1818V6.36364C20.5714 2.8491 23.4496 0 27 0Z"
        fill="url(#paint1_linear_204_480)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_204_480"
          x1="12.5357"
          y1="7.63635"
          x2="12.5357"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAAFF" />
          <stop offset="1" stopColor="#7C71FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_204_480"
          x1="24.0144"
          y1="-11.4309"
          x2="15.7249"
          y2="36.1784"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAAFF" />
          <stop offset="1" stopColor="#7C71FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
