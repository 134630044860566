import { LogoIcon } from "../images/LogoIcon";

export const Logo = () => {
  return (
    <div className="flex items-center gap-x-3">
      <LogoIcon />
      <span className="font-semibold">دونو</span>
    </div>
  );
};
