export const ZarinpalIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.535403 0.355774H5.0101C5.38875 0.355774 5.58172 0.811546 5.31593 1.07922L0.852163 5.56098C0.582735 5.83227 0.120338 5.64417 0.120338 5.26436L0.105774 0.782608C0.105774 0.547487 0.295102 0.355774 0.535403 0.355774ZM15.683 16.3558H11.2017C10.8228 16.3558 10.6297 15.9 10.8957 15.6323L15.3587 11.1506C15.6283 10.8793 16.091 11.0674 16.091 11.4472L16.1056 15.9289C16.1129 16.1641 15.9198 16.3558 15.683 16.3558ZM0.771629 12.4784L12.2314 1.0215C13.1192 0.133867 14.556 0.133867 15.4399 1.0215C16.3277 1.90909 16.3277 3.34554 15.4399 4.22928L3.9802 15.6901C3.09628 16.5777 1.65554 16.5777 0.771629 15.6901C-0.116178 14.8025 -0.116178 13.366 0.771629 12.4784Z"
      fill="#9053FF"
    />
  </svg>
);
