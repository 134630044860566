import posters from "../../../images/games-bg.png";
import hero from "../../../images/dono-world.png";

export const LoginHero = () => {
  return (
    <>
      <div className="flex items-center justify-center flex-col z-20 px-32 absolute left-0 inset-y-0">
        <div className="mb-8">
          <img src={hero} alt="قابلیت Mediashare" />
        </div>
        <h2 className="font-bold bg-[#EEEFF9] rounded-full px-5 py-3 mb-4">
          قابلیت Mediashare
        </h2>
        <p className="text-black/80 text-center max-w-[400px] leading-7">
          در نسخه جدید دونو میتوانید به ازای مقدار خاصی از دونیت مقدار تعیین شده
          ای از ویدیو ها را پخش کنید.
        </p>
      </div>
      <div className="h-screen bg-[#F6F7FF] w-[45%] fixed left-0 top-0 overflow-hidden flex items-center justify-center">
        <div
          className="posters absolute top-0 left-0 h-full transform-gpu bg-contain bg-repeat-x w-full"
          style={{ backgroundImage: `url(${posters})` }}
        />
      </div>
    </>
  );
};
