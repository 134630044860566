import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import { Auth } from "../../api/auth";

export const useLoginCallback = () => {
  const [params] = useSearchParams();
  const code = params.get("code");
  const state = params.get("state");
  const [, setCookie] = useCookies();

  useEffect(() => {
    (async () => {
      try {
        if (!code || !state) {
          return;
        }
        const { data } = await Auth.verifyTwitch({ code, state });
        const { token } = data;
        if (token) {
          setCookie("access_token", token);
        }
      } catch (e) {
        throw e;
      }
    })();
  }, [code, state, setCookie]);
};
