import { DotsHorizontalIcon } from "@heroicons/react/outline";
import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import fa from "timeago.js/lib/lang/fa";
import { Button } from "../../../components/Button";

timeago.register("fa", fa);

export const Card = ({
  image,
  title,
  date,
}: {
  image: string;
  title: string;
  date: Date;
}) => {
  return (
    <div>
      <img
        src={image}
        alt="تصویر"
        className="w-full h-[120px] object-cover rounded-t-2xl"
      />

      <div className="border border-[#ECECEC] rounded-b-2xl overflow-hidden">
        <div className="p-4">
          <div className="mb-4 flex justify-between">
            <div>
              <h2 className="font-semibold mb-[2px]">{title}</h2>
              <span className="text-[13px] opacity-60 font-medium">
                <TimeAgo datetime={date} locale="fa" className="mb-0" />
              </span>
            </div>
            <div className="shrink-0">
              <a>
                <DotsHorizontalIcon className="w-6 h-6 text-[#BDBEC8]" />
              </a>
            </div>
          </div>
          <Button type="secondary" block className="mt-4">
            ویرایش
          </Button>
        </div>
      </div>
    </div>
  );
};
