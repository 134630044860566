import { Card } from "../../../components/Card";
import Chart from "react-apexcharts";
import { options } from "../../../config/chart";

export const StatsChart = () => {
  const series = [
    {
      name: "مقدار",
      data: [45, 45, 52, 38, 45, 19, 23, 2, 2],
    },
  ];

  return (
    <Card title="آخرین حمایت ها">
      <div className="mixed-chart -mx-7 mt-8 -mb-4">
        <Chart
          options={options}
          series={series}
          type="area"
          width={"100%"}
          height="230px"
        />
      </div>
    </Card>
  );
};
