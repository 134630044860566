import saveIcon from "../images/save-icon.svg";

export const options = {
  chart: {
    animations: {
      enabled: false,
    },
    height: 250,
    toolbar: {
      tools: {
        download: `<img src="${saveIcon}" />`,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false,
      },
    },
  },
  colors: ["#9686FF"],
  stroke: {
    width: 3,
  },

  dataLabels: {
    enabled: false,
  },
  fill: {
    type: "gradient",
    gradient: {
      gradientToColors: ["#FFFFFF", "#FFFFFF"],
      shadeIntensity: 1,
      opacityFrom: 0.6,
      opacityTo: 0,
      stops: [0, 90],
    },
  },
  markers: {
    size: 3,
    colors: ["#9686FF"],
    strokeColor: "#9686FF",
    strokeWidth: 3,
    hover: {
      size: 5,
    },
  },
  grid: {
    borderColor: "#F0F0F0",
    yaxis: {
      lines: {
        show: false,
      },
    },
    xaxis: {
      floating: true,
      lines: {
        show: true,
      },
      labels: {
        formatter: function (val: any, opt: any) {
          return opt.w.globals.labels[opt.dataPointIndex] + ": " + val;
        },
      },
    },
    row: {
      opacity: 0,
    },
  },
  xaxis: {
    categories: [
      " ",
      "خرداد",
      "تیر",
      "مرداد",
      "شهریور",
      "دی",
      "بهمن",
      "اسفند",
      " ",
    ],
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    labels: {
      hideOverlappingLabels: false,
      offsetX: 0,
      style: {
        fontFamily: "IranSansX",
        fontSize: "12px",
        cssClass: "fill-[#A3A3A3]",
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    intersect: true,
    shared: false,
    style: {
      fontSize: "12px",
      fontFamily: "IranSansX",
    },
    onDatasetHover: {
      highlightDataSeries: false,
    },
  },
};
