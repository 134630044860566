import {
  CashIcon,
  CodeIcon,
  CogIcon,
  CollectionIcon,
  HomeIcon,
} from "@heroicons/react/outline";
import { ReactNode, SVGProps } from "react";
import { Dashboard } from "../pages/dashboard";
import { Donations } from "../pages/donations";
import { Login } from "../pages/login";
import { Overlays } from "../pages/overlays";

enum MenuGroup {
  Main,
  Settings,
}

export const routes: {
  title: string;
  id: string;
  path: string;
  Element: ReactNode;
  subPath?: boolean;
  group?: number;
  Icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  menuType?: "dashboard";
  hidden?: boolean;
}[] = [
  {
    title: "ورود",
    id: "login",
    path: "/login",
    Element: <Login />,
    // hidden: true,
  },
  {
    title: "داشبورد",
    id: "dashboard",
    path: "/dashboard",
    Element: <Dashboard />,
    Icon: HomeIcon,
    menuType: "dashboard",
    group: MenuGroup.Main,
  },
  {
    title: "پرداخت ها",
    id: "donations",
    path: "/donations",
    Element: <Donations />,
    Icon: CashIcon,
    menuType: "dashboard",
    group: MenuGroup.Main,
  },
  {
    title: "نمایه ها",
    id: "overlays",
    path: "/overlays",
    Element: <Overlays />,
    Icon: CollectionIcon,
    menuType: "dashboard",
    group: MenuGroup.Main,
  },
  {
    title: "تنظیمات",
    id: "settings",
    path: "/settings",
    Element: <Dashboard />,
    Icon: CogIcon,
    menuType: "dashboard",
    group: MenuGroup.Settings,
  },
  {
    title: "دریافت API",
    id: "api-settings",
    path: "/api-settings",
    Element: <Dashboard />,
    Icon: CodeIcon,
    menuType: "dashboard",
    group: MenuGroup.Settings,
  },
];
