import Input from "../../../components/Input";
import { useForm, FormProvider } from "react-hook-form";
import toast from "../../../helpers/toast";
import { Auth } from "../../../api/auth";
import { Button } from "../../../components/Button";
import twitchIcon from "../../../images/twitch.svg";
import aparatIcon from "../../../images/aparat.svg";
import { SetStateAction, Dispatch, useState } from "react";

export const LoginForm = ({
  setCodeSent,
}: {
  setCodeSent: Dispatch<SetStateAction<string>>;
}) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: { [key: string]: any }) => {
    try {
      const { email } = values;
      if (!email) {
        return;
      }
      setLoading(true);
      localStorage.setItem("loginServiceId", email);
      const { data } = await Auth.loginEmail({ email });
      if (!data) {
        return;
      }
      setCodeSent(data?.id);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleTwitchLogin = async () => {
    try {
      const { data } = await Auth.loginTwitch();
      if (!data) {
        return;
      }
      const { url } = data;
      if (!url) {
        return;
      }

      window.location.assign(url);
    } catch (e) {
      toast.error("مشکلی در ارتباط با توییچ پیش آمد.");
    }
  };

  return (
    <div className="max-w-[380px]">
      <h1 className="text-3xl font-black mb-4">به سفینه دونو خوش آمدید 👋🏻</h1>
      <p className="text-black/80 leading-8 mb-8">
        نگران هیچی نباشید، اگر توی توییچ اکانت دارید میتونید با اکانت توییچ تون
        وارد بشید در غیر این صورت ایمیل تون رو وارد کنید.
      </p>
      <div className="flex gap-x-5 mb-5">
        <div className="grow">
          <Button type="gray" block onClick={handleTwitchLogin}>
            <img src={twitchIcon} alt="ورود با توییچ" />
          </Button>
        </div>
        <div className="grow">
          <Button type="gray" block>
            <img src={aparatIcon} alt="ورود با آپارات" />
          </Button>
        </div>
      </div>
      <div className="w-full pt-3">
        <hr />
        <div className="bg-white mx-auto w-10 text-center relative -top-3 text-[#6D7588] text-[15px]">
          یا
        </div>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-6 space-y-3">
            <Input
              id="email"
              placeholder="آدرس ایمیل"
              validation={{ required: "لطفا ایمیل خود را وارد کنید." }}
            />
          </div>
          <div className="flex items-center justify-between">
            <span className="text-primary cursor-pointer text-[15px]">
              فراموشی نام کاربری؟
            </span>
            <Button type="primary" loading={loading}>
              ورود به سایت
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
