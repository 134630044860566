import { useEffect, useState } from "react";
import { Account } from "../../../api/account";
import { Button } from "../../../components/Button";
import { Card } from "../../../components/Card";
import toast from "../../../helpers/toast";
import { ZarinpalIcon } from "../../../images/ZarinpalIcon";

type Account = {
  id: string;
  userId: string;
  type: "TWITCH" | "ZARINPAL";
  createdAt: Date;
  updatedAt: Date;
};

export const Accounts = () => {
  const [accounts, setAccounts] = useState<Account[]>();

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data },
        } = await Account.getAll();
        if (!data) {
          return;
        }
        setAccounts(data);
      } catch (e) {
        toast.error("خطا در دریافت اکانت ها");
      }
    })();
  }, []);

  const typeDefinition = {
    ZARINPAL: "زرین پال",
    TWITCH: "توییچ",
  };

  return (
    <Card title="اکانت های متصل">
      {accounts && accounts?.length === 0 && (
        <div className="bg-[#F7F9FF] rounded-xl px-5 py-4 flex flex-col items-center">
          <p className="text-sm text-center text-gray-500 mb-2.5 leading-6">
            هیچ اکانتی به دونو شما لینک نشده، با لینک کردن سرویس های مختلف را به
            دونو متصل کنید.
          </p>
          {/*
            <div className="bg-gradient-to-r to-gray-400 from-[#cbaeff] p-px rounded-lg">
            <Button type="card">اتصال اکانت</Button>
          </div>
          */}
          <Button type="outline">اتصال اکانت</Button>
        </div>
      )}
      {accounts && (
        <div className="flex flex-col gap-y-4">
          {accounts?.map((account) => (
            <div className="bg-[#F7F9FF] rounded-xl px-5 py-4 flex gap-x-3.5">
              <div className="w-10 h-10 flex items-center justify-center bg-[#EEEBFF] rounded-xl">
                {account?.type === "ZARINPAL" && <ZarinpalIcon />}
                {account?.type === "TWITCH" && (
                  <svg
                    width="17"
                    height="19"
                    viewBox="0 0 17 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_136_144)">
                      <path
                        d="M4.29485 0.0961609L0.961517 3.31045V14.8819H4.96152V18.0962L8.29485 14.8819H10.9615L16.9615 9.09616V0.0961609H4.29485ZM15.6282 8.4533L12.9615 11.0247H10.2949L7.96152 13.2747V11.0247H4.96152V1.38188H15.6282V8.4533Z"
                        fill="#9053FF"
                      />
                      <path
                        d="M13.6282 3.63188H12.2949V7.48902H13.6282V3.63188Z"
                        fill="#9053FF"
                      />
                      <path
                        d="M9.96151 3.63188H8.62817V7.48902H9.96151V3.63188Z"
                        fill="#9053FF"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_136_144">
                        <rect
                          width="16"
                          height="18"
                          fill="white"
                          transform="translate(0.961517 0.0961609)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>
              <div>
                <h3 className="text-[15px] text-black font-medium">
                  {typeDefinition[account?.type] ||
                    account?.type?.toLowerCase()}
                </h3>
                <span className="text-gray-500 text-xs relative -top-0.5">
                  پیش فرض
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};
